import "./Home.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import ContactForm from "../../components/ContactForm/ContactForm";
import ScrollingLineGsap from "../../components/Utils/ScrollingLineGsap/ScrollingLineGsap";
import useWindowWidth from "../../hooks/useWindowWidth";
import AboutUs from "./AboutUs/AboutUs";
import Agenda from "./Agenda/Agenda";
import ArtistSeparator from "./ArtistSeparator/ArtistSeparator";
import Carrousel from "./Carrousel/Carrousel";
import CarrouselDesktop from "./CarrouselDesktop/CarrouselDesktop";
import Hero from "./Hero/Hero";
import ServicesSection from "./ServicesSection/ServicesSection";
import SoundHire from "./SoundHire/SoundHire";

const Home: React.FC = () => {
  const { isTabelet, isMobile } = useWindowWidth();
  const { t } = useTranslation();

  const carouselTitle = t("carousel.title");

  const animationOptions = {
    start: "top-=10% +=400 ",
    end: isMobile ? "bottom+=100% top" : "bottom top",
    duration: 4,
    scrub: 1,
    markers: false,
  };

  return (
    <div className="home">
      <Hero />
      <ScrollingLineGsap
        animationOptions={animationOptions}
        className="lineAgenda"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1951"
            height="1419"
            viewBox="0 0 1951 1419"
            fill="none"
          >
            <path
              d="M15 15.5C15 15.5 242.719 77.1383 425.922 278.319C609.124 479.499 619.353 733.751 537.213 917.387C455.073 1101.02 229.422 1165.91 119.015 1056.5C14.545 952.981 15 806.096 212.756 677.176C410.512 548.256 812.694 572.531 1041.88 689.668C1273.02 807.808 1264.46 895.985 1384.31 1002.14C1479.28 1086.26 1626.16 897.697 1776.4 979.026C1874.42 1034.24 1752.03 1148.77 1878.7 1231.57C2005.37 1314.38 1878.7 1336.4 1878.7 1403.57"
              stroke="#72D6E3"
              strokeWidth="30"
              strokeLinecap="round"
            />
          </svg>
        }
      />
      <Agenda />

      {isTabelet ? (
        <Carrousel
          imgArray={[
            "popularParties/popularParties1.png",
            "popularParties/popularParties3.jpg",
            "popularParties/popularParties6.jpg",
            "popularParties/popularParties4.jpg",
            "popularParties/popularParties5.jpg",
            "popularParties/popularParties2.jpg",
            "popularParties/popularParties7.jpg",
          ]}
          title={carouselTitle}
        />
      ) : (
        <CarrouselDesktop
          imgArray={[
            "popularParties/popularParties1.png",
            "popularParties/popularParties3.jpg",
            "popularParties/popularParties6.jpg",
            "popularParties/popularParties4.jpg",
            "popularParties/popularParties5.jpg",
            "popularParties/popularParties2.jpg",
            "popularParties/popularParties7.jpg",
          ]}
          title={carouselTitle}
        />
      )}

      <ArtistSeparator />
      <AboutUs />
      <SoundHire />
      <ServicesSection />
      <ContactForm />
    </div>
  );
};

export default Home;
